import clsx from "clsx"
import { Table } from "@src/components/organisms/Table"
import { columnsToRows } from "./utils"

export type Column = {
  title: string
  id: number
  answers?: string[] | number[] | null
}

export type TableAnswerProps = {
  columns: Column[]
  className?: string
}

export const TableAnswer = ({ columns, className }: TableAnswerProps) => {
  const rows = columnsToRows(columns)
  const tableColumnsDef = columns.map((column) => ({
    header: column.title,
    accessorKey: String(column.id),
  }))

  return (
    <div className={clsx("mb-6", className)}>
      <Table columns={tableColumnsDef} data={rows} />
    </div>
  )
}
