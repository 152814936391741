import { client } from "./client"

export const deleteMethodology = async (communityId: number, id: number) => {
  const data = await client.DELETE("/api/Methodology/{communityId}/{id}", {
    params: {
      path: { id, communityId },
    },
  })

  return data
}

export const toggleMethodologyVisibility = async (
  id: number,
  enable: boolean,
) => {
  if (enable) {
    return await client.GET("/api/Methodology/Enable", {
      params: {
        query: { id },
      },
    })
  } else {
    return await client.GET("/api/Methodology/Disable", {
      params: {
        query: { id },
      },
    })
  }
}
