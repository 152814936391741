import { client } from './client'
import { safeQueryOptions} from "@src/utils/query.ts"
import { UnpackArray, UnpackPromise } from "@src/utils/types.ts"

export const coachingRequestsKeys = {
  all: ["coachingRequests"],
  requestsList: (communityId: number) => [
    ...coachingRequestsKeys.all,
    "list",
    "requests",
    communityId
  ],
  defaultCircles: (communityId?: number) => [
    ...coachingRequestsKeys.all,
    "circles",
    communityId
  ],
}

export const getCoachingRequestsByCommunity = async (
  communityId: number,
)=> {
  const data = await client.GET(`/api/Admin/community/{communityId}/requests`, {
    params: {
      path: { communityId },
    },
  })

  return data
}

export type CoachingRequestsByCommunity = UnpackArray<
  UnpackPromise<ReturnType<typeof getCoachingRequestsByCommunity>>
>

export const getCoachingRequestsByCommunityQuery = (
  communityId: number,
) =>
  safeQueryOptions({
    queryFn: getCoachingRequestsByCommunity,
    queryKeys: coachingRequestsKeys.requestsList(communityId),
    unsafeQueryFnArgs: [communityId],
  })

export const exportToCsv = async (
  communityId: number
) => {
  const data = await client.GET("/api/Admin/community/{communityId}/requests-csv",
    {
      params: {
        path: { communityId },
      },
    })

  return data
}

export const getRequestsDefaultCircles = async (
  communityId: number
) => {
  const data = await client.GET("/api/Request/community/{communityId}/defaultCircles", {
      params: {
        path: { communityId },
      },
    })

  return data
}

export const getRequestsDefaultCirclesQuery = (
  communityId?: number
) =>
  safeQueryOptions({
    queryFn: getRequestsDefaultCircles,
    queryKeys: coachingRequestsKeys.defaultCircles(communityId),
    unsafeQueryFnArgs: [communityId],
  })

export const updateRequestsDefaultCircles = async (
  communityId: number,
  circles: number[]
)=> {
  const data = await client.POST(
    "/api/Request/community/{communityId}/defaultCircles",
    {
      params: {
        path: {communityId},
      },
      body: circles,
    },
  )

  return data
}
