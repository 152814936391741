import { useMemo } from "react"
import clsx from "clsx"
import {
  RequestsStatusEnum,
  RequestsTypeEnum,
  RequstsCsatEnum,
  CoachingRequestsFilters,
  buildFilterValues,
} from "@src/components/pages/Coaching/utils.ts"
import { ClientFilters } from "@src/components/organisms/Filters/ClientFilters"
import { RequestsItem } from "@src/components/pages/Coaching/index"
import { RoutesWithClientFilters } from "@src/components/organisms/Filters/ClientFilters"


export type CoachingFiltersProps = {
  values: CoachingRequestsFilters
  itemsList: RequestsItem[]
  onChange: (values: CoachingRequestsFilters) => void
  route?: RoutesWithClientFilters
  className?: string,
}

const sessionTypeFilterValues = [
  {id: RequestsTypeEnum.All, label: "All"},
  {id: RequestsTypeEnum.Coaching, label: "Coaching"},
  {id: RequestsTypeEnum.Request, label: "Request"},
]

const statusFilterValues = [
  { id: RequestsStatusEnum.All, label: "All"},
  { id: RequestsStatusEnum.Open, label: "Open"},
  { id: RequestsStatusEnum.Assigned, label: "Assigned"},
  { id: RequestsStatusEnum.Closed, label: "Closed"},
]

const scoreFilterValues = [
  {id: RequstsCsatEnum.All, label: "All"},
  {id: RequstsCsatEnum.OneOutOf, label: "1/5"},
  {id: RequstsCsatEnum.TwoOutOf, label: "2/5"},
  {id: RequstsCsatEnum.ThreeOutOf, label: "3/5"},
  {id: RequstsCsatEnum.FourOutOf, label: "4/5"},
  {id: RequstsCsatEnum.FiveOutOf, label: "5/5"},
]

export const CoachingFilters = ({
  values,
  onChange,
  className,
  itemsList,
  route,
}: CoachingFiltersProps) => {

  const sessionNameFilterValues = useMemo(() => {
    if (!itemsList) return []
    return buildFilterValues(itemsList, 'name')
  }, [itemsList])

  const startupNameFilterValues = useMemo(() => {
    if (!itemsList) return []
    return buildFilterValues(itemsList, 'projectName')
  }, [itemsList])

  const attendeesFilterValues = useMemo(() => {
    if (!itemsList) return []
    return buildFilterValues(itemsList, 'advisorName')
  }, [itemsList])

  const filterOptions = {
    sessionTypeFilter: {
      type: "select" as const,
      options: sessionTypeFilterValues,
      label: "Session Type",
    },
    sessionNameFilter: {
      type: "combobox" as const,
      options: sessionNameFilterValues,
      label: "Session Name",
    },
    startupNameFilter: {
      type: "combobox" as const,
      options: startupNameFilterValues,
      label: "Project Name",
    },
    statusFilter: {
      type: "select" as const,
      options: statusFilterValues,
      label: "Status",
    },
    attendeesFilter: {
      type: "combobox" as const,
      options: attendeesFilterValues,
      label: "Attendees",
    },
    creationDateFilter: {
      type: "date" as const,
      label: "Created Date",
    },
    guestScoreFilter: {
      type: "select" as const,
      options: scoreFilterValues,
      label: "Guest CSAT",
    },
    creatorScoreFilter: {
      type: "select" as const,
      options: scoreFilterValues,
      label: "Creator CSAT",
    }
  } as const

  return (
    <div className={clsx("flex flex-wrap gap-2", className)}>
      <ClientFilters
        values={values}
        options={filterOptions}
        onChange={onChange}
        showLabels={false}
        showResetButton={true}
        route={ route ? route : "/_auth/_community/community/$communityId/_admin-panel/admin-panel/requests-status/"}
      />
    </div>
  )
}
