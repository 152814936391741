import { createFileRoute } from "@tanstack/react-router"

import { ApplicationDefinitionPage } from "@src/components/pages/Applications/ApplicationDefinitionPage"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/application-definitions/$applicationId/",
)({
  component: () => <ApplicationDefinitionPageWrapper />,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      duplicate: search.duplicate === true ? true : false,
    }
  },
})

function ApplicationDefinitionPageWrapper() {
  const { applicationId } = Route.useParams()
  const { duplicate } = Route.useSearch()

  const parsedApplicationId = parseInt(applicationId)

  return (
    <ApplicationDefinitionPage
      surveyId={parsedApplicationId}
      duplicate={duplicate}
    />
  )
}
