import { Checkbox } from "@src/components/atoms/Checkbox"
import { FormAnswerDto, FormQuestionDto } from "@src/api/form"

export type CheckboxAnswerProps = {
  question: FormQuestionDto
  answers: FormAnswerDto[]
}

export const CheckboxAnswer = ({ question, answers }: CheckboxAnswerProps) => {
  // We don't need an onChange, but it's required by the component.
  // Not using an empty function as it cannot be covered in the tests
  // coverage report.
  return question?.questionOptions?.map((option) => (
    <Checkbox
      key={option.questionOptionOrder}
      label={option.questionOption || ""}
      checked={answers.some(
        (answer) => answer.optionAnswerOrder === option.questionOptionOrder,
      )}
      onChange={undefined as unknown as () => unknown}
      className={"pointer-events-none pb-2"}
    />
  ))
}
