import { http, HttpResponse } from "msw"

import { API_BASE_URL } from "@src/utils/consts"
import { AssertionFunction } from "./common"
import { SurveyDefinition, SurveyStatus } from "./survey"

export const getSurveyDefinition_SuccessResponse = (id: number) => {
  const response: SurveyDefinition = {
    id,
    title: "Startup Feedback Survey",
    description:
      "We have selected 12 startups who are currently enrolled in our programme. The programme started in July and will continue until this year’s Demo Day which will happen in November 2024. Over these four months, these startups will undergo an intensive program, including local and global sessions, coaching, and pitching practice sessions, all aimed at refining their innovative solutions. Each startup will have a dedicated coach and, by the end of the program, will receive a €10,000 grant to further their impact. At the end of the program the startups will participate in a demo day, where the top 3 startups will be awarded with €20,000, €10,000 and €5,000 respectively.",
    surveyType: 1,
    communityId: 1,
    formId: 1515,
    recurrence: 0,
    recurrency: 0,
    KPIConvert: true,
    startDate: new Date("2024-01-01T09:00:00Z"),
    startDateTimezoneCode: "Europe/Bucharest",
    endDate: new Date("2024-01-31T18:00:00Z"),
    endDateTimezoneCode: "Europe/Bucharest",
    circleList: [11, 22, 33, 1886],
    tags: ["feedback", "satisfaction", "New2025"],
    methodologyList: [101, 202],
    deadline: new Date("2025-01-31T18:00:00Z"),
    form: {
      name: "Form name",
      description: "<p>Test all questions</p>",
      communityId: 241,
      type: 0,
      questions: [
        {
          id: 15,
          title: "First question test?",
          description: "<p>Long question description</p>",
          question: null,
          questionOrder: 1,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 7,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [],
          isFilterable: null,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 16,
          title: "Second question test?",
          description: "<p>Long question description</p>",
          question: null,
          questionOrder: 1,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 8,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [],
          isFilterable: null,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 17,
          title: "Numeric question test",
          description: "<p>Long question description</p>",
          question: null,
          questionOrder: 1,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 13,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [],
          isFilterable: null,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 1966,
          title: "Table",
          description: "",
          question: "Table",
          questionOrder: 6,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 12,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [
            {
              id: 3537,
              questionOption: "This is column 1",
              questionOptionOrder: 0,
            },
            {
              id: 3538,
              questionOption: "This is column 2",
              questionOptionOrder: 1,
            },
          ],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 1963,
          title: "Radio button",
          description: "",
          question: "Radio button",
          questionOrder: 3,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 0,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [
            {
              id: 3529,
              questionOption: "Option 1",
              questionOptionOrder: 0,
            },
            {
              id: 3530,
              questionOption: "Option 2",
              questionOptionOrder: 1,
            },
            {
              id: 3531,
              questionOption: "Option 3",
              questionOptionOrder: 2,
            },
          ],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 1964,
          title: "Checkbox",
          description: "",
          question: "Checkbox",
          questionOrder: 4,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 1,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [
            {
              id: 3532,
              questionOption: "Checkbox option 1",
              questionOptionOrder: 0,
            },
            {
              id: 3533,
              questionOption: "Checkbox option 2",
              questionOptionOrder: 1,
            },
          ],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 1965,
          title: "Multiple choices",
          description: "",
          question: "Multiple choices",
          questionOrder: 5,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 3,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [
            {
              id: 3534,
              questionOption: "Multiple choice 1",
              questionOptionOrder: 0,
            },
            {
              id: 3535,
              questionOption: "Multiple choice 2",
              questionOptionOrder: 1,
            },
            {
              id: 3536,
              questionOption: "Multiple choice 3",
              questionOptionOrder: 2,
            },
          ],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 1967,
          title: "Dropdown",
          description: "",
          question: "Dropdown",
          questionOrder: 7,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 2,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [
            {
              id: 3539,
              questionOption: "1",
              questionOptionOrder: 0,
            },
            {
              id: 3540,
              questionOption: "2",
              questionOptionOrder: 1,
            },
            {
              id: 3541,
              questionOption: "3",
              questionOptionOrder: 2,
            },
          ],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 2075,
          title: "How much money have you raised so far for your startup?",
          description: "",
          question: "How much money have you raised so far for your startup?",
          questionOrder: 11,
          mandatoryAnswer: false,
          editableAnswer: false,
          answerType: 4,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 1,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 2073,
          title: "Scale",
          description: "",
          question: "Scale",
          questionOrder: 9,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 10,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [
            {
              id: 3777,
              questionOption: "From",
              questionOptionOrder: 1,
            },
            {
              id: 3778,
              questionOption: "",
              questionOptionOrder: 2,
            },
            {
              id: 3779,
              questionOption: "",
              questionOptionOrder: 3,
            },
            {
              id: 3780,
              questionOption: "",
              questionOptionOrder: 4,
            },
            {
              id: 3781,
              questionOption: "",
              questionOptionOrder: 5,
            },
            {
              id: 3782,
              questionOption: "",
              questionOptionOrder: 6,
            },
            {
              id: 3783,
              questionOption: "",
              questionOptionOrder: 7,
            },
            {
              id: 3784,
              questionOption: "To",
              questionOptionOrder: 8,
            },
          ],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 2074,
          title: "Calendar",
          description: "",
          question: "Calendar",
          questionOrder: 10,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 5,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 2066,
          title: "Countries",
          description: "",
          question: "Countries",
          questionOrder: 2,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 11,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
        {
          id: 2068,
          title: "Files",
          description: "",
          question: "Files",
          questionOrder: 8,
          mandatoryAnswer: true,
          editableAnswer: false,
          answerType: 9,
          allowMultipleAnswer: false,
          questionLinks: [],
          questionOptions: [],
          isFilterable: false,
          privacyLevel: 1,
          conditionalLogicType: 0,
          formQuestionConditionalLogicList: [],
        },
      ],
    },
  }

  return response
}

export const getSurveyDefinitionList_SuccessResponse = (
  communityId: number,
) => {
  const response: SurveyDefinition[] = [
    {
      id: 1,
      title: "Survey 1",
      communityId: communityId,
      description:
        "We have selected 12 startups who are currently enrolled in our programme. The programme started in July and will continue until this year’s Demo Day which will happen in November 2024. Over these four months, these startups will undergo an intensive program, including local and global sessions, coaching, and pitching practice sessions, all aimed at refining their innovative solutions. Each startup will have a dedicated coach and, by the end of the program, will receive a €10,000 grant to further their impact. At the end of the program the startups will participate in a demo day, where the top 3 startups will be awarded with €20,000, €10,000 and €5,000 respectively.",
      surveyType: 1,
      formId: 1515,
      recurrence: 0,
      KPIConvert: true,
      startDate: new Date("2024-01-01T09:00:00Z"),
      startDateTimezoneCode: "Europe/Bucharest",
      endDate: new Date("2024-01-31T18:00:00Z"),
      endDateTimezoneCode: "Europe/Bucharest",
      circleList: [11, 22, 33, 1886],
      tags: ["feedback", "satisfaction", "New2025"],
      methodologyList: [101, 202],
      deadline: new Date("2024-03-01T09:00:00Z"),
      recurrency: 0,
    },
    {
      id: 2,
      title: "Survey 2",
      description:
        "We have selected 12 startups who are currently enrolled in our programme. The programme started in July and will continue until this year’s Demo Day which will happen in November 2024. Over these four months, these startups will undergo an intensive program, including local and global sessions, coaching, and pitching practice sessions, all aimed at refining their innovative solutions. Each startup will have a dedicated coach and, by the end of the program, will receive a €10,000 grant to further their impact. At the end of the program the startups will participate in a demo day, where the top 3 startups will be awarded with €20,000, €10,000 and €5,000 respectively.",
      surveyType: 1,
      communityId: communityId,
      formId: 1515,
      recurrence: 0,
      KPIConvert: true,
      startDate: new Date("2024-01-01T09:00:00Z"),
      startDateTimezoneCode: "Europe/Bucharest",
      endDate: new Date("2024-01-31T18:00:00Z"),
      endDateTimezoneCode: "Europe/Bucharest",
      circleList: [11, 22, 33, 1886],
      tags: ["feedback", "satisfaction", "New2025"],
      methodologyList: [101, 202],
      deadline: new Date("2024-03-01T09:00:00Z"),
      recurrency: 0,
    },
    {
      id: 3,
      title: "Survey 3",
      description:
        "We have selected 12 startups who are currently enrolled in our programme. The programme started in July and will continue until this year’s Demo Day which will happen in November 2024. Over these four months, these startups will undergo an intensive program, including local and global sessions, coaching, and pitching practice sessions, all aimed at refining their innovative solutions. Each startup will have a dedicated coach and, by the end of the program, will receive a €10,000 grant to further their impact. At the end of the program the startups will participate in a demo day, where the top 3 startups will be awarded with €20,000, €10,000 and €5,000 respectively.",
      surveyType: 1,
      communityId: communityId,
      formId: 1515,
      recurrence: 0,
      KPIConvert: true,
      startDate: new Date("2024-01-01T09:00:00Z"),
      startDateTimezoneCode: "Europe/Bucharest",
      endDate: new Date("2024-01-31T18:00:00Z"),
      endDateTimezoneCode: "Europe/Bucharest",
      circleList: [11, 22, 33, 1886],
      tags: ["feedback", "satisfaction", "New2025"],
      methodologyList: [101, 202],
      deadline: new Date("2024-03-01T09:00:00Z"),
      recurrency: 0,
    },
  ]

  return response
}

export const getSurveyDefinition_SuccessHandler = (
  assertions?: AssertionFunction,
) =>
  http.get(
    `${API_BASE_URL}/api/survey-definition/:id`,
    ({ params, request }) => {
      if (assertions) {
        assertions({ params, request })
      }

      return HttpResponse.json(getSurveyDefinition_SuccessResponse(1))
    },
  )

export const getSurveyDefinitionList_SuccessHandler = (
  assertions?: AssertionFunction,
) =>
  http.get(`${API_BASE_URL}/api/survey-definition`, ({ params, request }) => {
    if (assertions) {
      assertions({ params, request })
    }

    return HttpResponse.json(getSurveyDefinitionList_SuccessResponse)
  })

export const getSurveyResponse_SuccessResponse = (surveyId: number) => {
  const response = {
    id: surveyId,
    answers: [
      {
        id: 1,
        formId: 5,
        formQuestionId: 15,
        userId: 43,
        lastSubmitedTime: "2024-12-01T00:00:00",
        answer:
          "Objectively composed of unrelated words, Lorem ipsum owes its existence to Marco Tullio Cicerone and to some steps of his De finibus bonorum et malorum (The highest good and the highest evil) written in 45 BC , a classic of Latin literature dating back more than 2000 years ago.",
        optionAnswerOrder: 1,
        optionRowAnswerOrder: 2,
      },
      {
        id: 2,
        formId: 5,
        formQuestionId: 16,
        userId: 43,
        lastSubmitedTime: "2024-12-01T00:00:00",
        answer: "Maybe this is my answer, maybe not",
        optionAnswerOrder: 1,
        optionRowAnswerOrder: 2,
      },
      {
        id: 3,
        formId: 5,
        formQuestionId: 17,
        userId: 43,
        lastSubmitedTime: "2024-12-01T00:00:00",
        answer: "88",
        optionAnswerOrder: 1,
        optionRowAnswerOrder: 1,
      },
      {
        id: 10276,
        formId: 1515,
        formQuestionId: 1966,
        userId: 402113,
        lastSubmitedTime: null,
        applicationId: 1316,
        tabEntityId: 0,
        projectId: 0,
        answer: "Cell 1-1",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 0,
      },
      {
        id: 10277,
        formId: 1515,
        formQuestionId: 1966,
        userId: 402113,
        lastSubmitedTime: null,
        applicationId: 1316,
        tabEntityId: 0,
        projectId: 0,
        answer: "Cell 1-2",
        optionAnswerOrder: 1,
        optionRowAnswerOrder: 0,
      },
      {
        id: 10278,
        formId: 1515,
        formQuestionId: 1966,
        userId: 402113,
        lastSubmitedTime: null,
        applicationId: 1316,
        tabEntityId: 0,
        projectId: 0,
        answer: "Cell 2-1",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 1,
      },
      {
        id: 10279,
        formId: 1515,
        formQuestionId: 1966,
        userId: 402113,
        lastSubmitedTime: null,
        applicationId: 1316,
        tabEntityId: 0,
        projectId: 0,
        answer: "Cell 2-2",
        optionAnswerOrder: 1,
        optionRowAnswerOrder: 1,
      },
      {
        id: 10280,
        formId: 1515,
        formQuestionId: 1966,
        userId: 402113,
        lastSubmitedTime: null,
        applicationId: 1316,
        tabEntityId: 0,
        projectId: 0,
        answer: "Cell 3-1",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 2,
      },
      {
        id: 10281,
        formId: 1515,
        formQuestionId: 1966,
        userId: 402113,
        lastSubmitedTime: null,
        applicationId: 1316,
        tabEntityId: 0,
        projectId: 0,
        answer: "Cell 3-2",
        optionAnswerOrder: 1,
        optionRowAnswerOrder: 2,
      },
      {
        id: 10272,
        formId: 1515,
        formQuestionId: 1963,
        userId: 402113,
        lastSubmitedTime: null,
        applicationId: 1316,
        tabEntityId: 0,
        projectId: 0,
        answer: "",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 0,
      },
      {
        id: 10389,
        formId: 1515,
        formQuestionId: 1964,
        userId: 401144,
        lastSubmitedTime: null,
        applicationId: 1317,
        tabEntityId: 0,
        projectId: 0,
        answer: "",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 0,
      },
      {
        id: 10523,
        formId: 1515,
        formQuestionId: 1965,
        userId: 401144,
        lastSubmitedTime: null,
        applicationId: 1317,
        tabEntityId: 0,
        projectId: 0,
        answer: "",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 0,
      },
      {
        id: 10521,
        formId: 1515,
        formQuestionId: 1967,
        userId: 402113,
        lastSubmitedTime: null,
        applicationId: 1316,
        tabEntityId: 0,
        projectId: 0,
        answer: "",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 0,
      },
      {
        id: 10298,
        formId: 1515,
        formQuestionId: 2075,
        userId: 401144,
        lastSubmitedTime: null,
        applicationId: 1317,
        tabEntityId: 0,
        projectId: 0,
        answer: "45512EUR",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 0,
      },
      {
        id: 10296,
        formId: 1515,
        formQuestionId: 2073,
        userId: 401144,
        lastSubmitedTime: null,
        applicationId: 1317,
        tabEntityId: 0,
        projectId: 0,
        answer: "",
        optionAnswerOrder: 5,
        optionRowAnswerOrder: 0,
      },
      {
        id: 10441,
        formId: 1515,
        formQuestionId: 2074,
        userId: 401144,
        lastSubmitedTime: null,
        applicationId: 1317,
        tabEntityId: 0,
        projectId: 0,
        answer: "2025-03-08T10:51:00.000Z",
        optionAnswerOrder: 0,
        optionRowAnswerOrder: 0,
      },
    ],
    userId: 15,
    projectId: 8063,
    status: 0,
    tags: ["feedback", "satisfaction"],
    iteration: 1,
    evaluators: [],
    evaluation: 0,
  }

  return response
}

export const getSurveyResponse_SuccessHandler = (
  assertions?: AssertionFunction,
) =>
  http.get(
    `${API_BASE_URL}/api/survey-response/:surveyId`,
    ({ params, request }) => {
      if (assertions) {
        assertions({ params, request })
      }

      return HttpResponse.json(
        getSurveyResponse_SuccessResponse(Number(params.surveyId)),
      )
    },
  )

export const getSurveyResponseList_SuccessResponse = () => {
  return [
    {
      id: 1,
      answers: [
        {
          id: 1,
          formId: 5,
          formQuestionId: 15,
          userId: 43,
          lastSubmitedTime: "2024-12-01T00:00:00",
          answer:
            "Objectively composed of unrelated words, Lorem ipsum owes its existence to Marco Tullio Cicerone and to some steps of his De finibus bonorum et malorum (The highest good and the highest evil) written in 45 BC , a classic of Latin literature dating back more than 2000 years ago.",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 2,
        },
        {
          id: 2,
          formId: 5,
          formQuestionId: 16,
          userId: 43,
          lastSubmitedTime: "2024-12-01T00:00:00",
          answer: "Maybe this is my answer, maybe not",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 2,
        },
        {
          id: 3,
          formId: 5,
          formQuestionId: 17,
          userId: 43,
          lastSubmitedTime: "2024-12-01T00:00:00",
          answer: "88",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 1,
        },
        {
          id: 10276,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 1-1",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10277,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 1-2",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10278,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 2-1",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 1,
        },
        {
          id: 10279,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 2-2",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 1,
        },
        {
          id: 10280,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 3-1",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 2,
        },
        {
          id: 10281,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 3-2",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 2,
        },
        {
          id: 10272,
          formId: 1515,
          formQuestionId: 1963,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10389,
          formId: 1515,
          formQuestionId: 1964,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10523,
          formId: 1515,
          formQuestionId: 1965,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10521,
          formId: 1515,
          formQuestionId: 1967,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10298,
          formId: 1515,
          formQuestionId: 2075,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "45512EUR",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10296,
          formId: 1515,
          formQuestionId: 2073,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 5,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10512,
          formId: 1515,
          formQuestionId: 2074,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "2025-03-08T10:51:00.000Z",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10237,
          formId: 1527,
          formQuestionId: 2066,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10297,
          formId: 1515,
          formQuestionId: 2068,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "/uploads/file.pdf",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
      ],
      userId: 43,
      status: SurveyStatus.Submitted,
      tags: ["Tag 1", "Tag 2"],
      iteration: 1,
      evaluators: ["Evaluator 1", "Evaluator 2"],
      evaluation: 1,
      projectName: "Greenfield",
    },
    {
      id: 2,
      answers: [
        {
          id: 4,
          formId: 5,
          formQuestionId: 15,
          userId: 43,
          lastSubmitedTime: "2024-12-01T00:00:00",
          answer:
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit, amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt, ut labore et dolore magnam aliquam quaerat voluptatem.",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 1,
        },
        {
          id: 5,
          formId: 5,
          formQuestionId: 16,
          userId: 43,
          lastSubmitedTime: "2024-12-01T00:00:00",
          answer: "I cannot answer this question",
          optionAnswerOrder: 2,
          optionRowAnswerOrder: 2,
        },
        {
          id: 6,
          formId: 5,
          formQuestionId: 17,
          userId: 43,
          lastSubmitedTime: "2024-12-01T00:00:00",
          answer: "99",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 1,
        },
        {
          id: 10276,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 1-1",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10277,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 1-2",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10278,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 2-1",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 1,
        },
        {
          id: 10279,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 2-2",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 1,
        },
        {
          id: 10280,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 3-1",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 2,
        },
        {
          id: 10281,
          formId: 1515,
          formQuestionId: 1966,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "Cell 3-2",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 2,
        },
        {
          id: 10273,
          formId: 1515,
          formQuestionId: 1963,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10289,
          formId: 1515,
          formQuestionId: 1964,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10290,
          formId: 1515,
          formQuestionId: 1964,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10526,
          formId: 1515,
          formQuestionId: 1965,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10529,
          formId: 1515,
          formQuestionId: 1965,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 2,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10451,
          formId: 1515,
          formQuestionId: 1967,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10298,
          formId: 1527,
          formQuestionId: 2075,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "24421EUR",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10333,
          formId: 1515,
          formQuestionId: 2073,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 4,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10697,
          formId: 1515,
          formQuestionId: 2074,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "2025-06-08T10:51:00.000Z",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10257,
          formId: 1527,
          formQuestionId: 2066,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 2,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10255,
          formId: 1515,
          formQuestionId: 2068,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "/uploads/file2.pdf",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
      ],
      userId: 43,
      status: SurveyStatus.Submitted,
      tags: ["Tag 1", "Tag 2"],
      iteration: 1,
      evaluators: ["Evaluator 2", "Evaluator 3"],
      evaluation: 1,
      projectName: "Save the ice caps",
    },
    {
      id: 3,
      answers: [
        {
          id: 3,
          formId: 5,
          formQuestionId: 15,
          userId: 43,
          lastSubmitedTime: "2024-12-01T00:00:00",
          answer:
            "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit, qui in ea voluptate velit esse, quam nihil molestiae consequatur, vel illum, qui dolorem eum fugiat, quo voluptas nulla pariatur? [33] At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati cupiditate non provident, similique sunt in culpa, qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio, cumque nihil impedit, quo minus id, quod maxime placeat, facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. ",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 1,
        },
        {
          id: 10272,
          formId: 1515,
          formQuestionId: 1963,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 451,
          formId: 5,
          formQuestionId: 17,
          userId: 43,
          lastSubmitedTime: "2024-12-01T00:00:00",
          answer: "39",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 1,
        },
        {
          id: 10244,
          formId: 1515,
          formQuestionId: 1964,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10296,
          formId: 1515,
          formQuestionId: 1964,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10526,
          formId: 1515,
          formQuestionId: 1965,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10751,
          formId: 1515,
          formQuestionId: 1967,
          userId: 402113,
          lastSubmitedTime: null,
          applicationId: 1316,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 1,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10298,
          formId: 1527,
          formQuestionId: 2075,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "65000USD",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10267,
          formId: 1515,
          formQuestionId: 2073,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 2,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10241,
          formId: 1515,
          formQuestionId: 2074,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "2025-09-08T10:51:00.000Z",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10287,
          formId: 1527,
          formQuestionId: 2066,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "",
          optionAnswerOrder: 3,
          optionRowAnswerOrder: 0,
        },
        {
          id: 10292,
          formId: 1515,
          formQuestionId: 2068,
          userId: 401144,
          lastSubmitedTime: null,
          applicationId: 1317,
          tabEntityId: 0,
          projectId: 0,
          answer: "/uploads/file3.pdf",
          optionAnswerOrder: 0,
          optionRowAnswerOrder: 0,
        },
      ],
      userId: 43,
      status: SurveyStatus.Draft,
      tags: ["Tag 1", "Tag 2"],
      iteration: 1,
      evaluators: ["Evaluator 2", "Evaluator 3"],
      evaluation: 1,
      projectName: "Save the whales",
    },
  ]
}
