import { FormQuestionDto } from "@src/api/form"
import { Icon } from "@src/components/atoms/Icon"
import React from "react"

export type QuestionProps = {
  question: FormQuestionDto
  children: React.ReactNode | React.ReactNode[]
  showOptions?: boolean
  showIcon?: boolean
}

export const Question = ({ question, children, showIcon }: QuestionProps) => {
  return (
    <div className="mb-6">
      <h4 className="flex gap-2 text-paragraph font-bold text-black">
        {showIcon && <Icon icon="quiz" />}
        <span>{question?.question || question?.title}</span>
      </h4>
      <div className="mt-3">{children}</div>
    </div>
  )
}
