import { createFileRoute } from '@tanstack/react-router'
// import { z } from "zod"
import {
  SurveysResponsesPage,
  SurveyResponsesFilters,
} from '@src/components/pages/Surveys/Responses'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/surveys/$surveyId/responses',
)({
  component: SurveyResponsesPageWrapper,
  validateSearch: () => ({}) as SurveyResponsesFilters,
})

function SurveyResponsesPageWrapper() {
  const { surveyId } = Route.useParams()

  // TODO: handle failing to parse surveyId
  const parsedSurveyId = parseInt(surveyId)

  return <SurveysResponsesPage surveyId={parsedSurveyId} />
}
