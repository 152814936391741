import { t } from "@lingui/macro"

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL.endsWith("/")
  ? import.meta.env.VITE_API_BASE_URL.slice(0, -1)
  : import.meta.env.VITE_API_BASE_URL

export const STAGING_URL = "app-new-stag.babele.co"

export const PROD_URL = "app-new.babele.co"

export const V1_APP_URL =
  import.meta.env.MODE === "production"
    ? "https://app.babele.co"
    : "https://app-stag.babele.co"

export const UPLOAD_FOLDERS = {
  resourceFiles: "resource_files",
  tabImages: "tab_image",
  businessPlanLogos: "businessplan_logo",
  discussionImages: "discussion_image",
  communityImages: "community_image",
  profilePictures: "profile_picture",
  customLogos: "custom_logo",
} as const

export type FileUploadFolder =
  (typeof UPLOAD_FOLDERS)[keyof typeof UPLOAD_FOLDERS]

export const UPLOAD_FOLDERS2 = [
  "resource_files",
  "tab_image",
  "businessplan_logo",
  "discussion_image",
  "community_image",
  "profile_picture",
  "custom_logo",
] as const

export const SKILLS_IMAGES = [
  "BusinessStrategy",
  "MarketingPR",
  "EntreIntrapreneurship",
  "InformationTechnology",
  "Legal",
  "NaturalSocialSciences",
  "ProductDesign",
  "Engineering",
  "Others",
  "FinanceAccounting",
  "Management",
  "HumanResources",
  "FundingCapital",
  "Sales",
  "Languages",
  "BusinessOperations",
  "Buildingandconstruction",
  "CleanEnergy",
  "Lifescience",
  "FintechandInsurtech",
] as const

export const getSkillImage = (skill: (typeof SKILLS_IMAGES)[number]) => {
  return `${V1_APP_URL}/static/skill-categories/${skill}`
}

// These are hardcoded in the frontend only, the backend doesn't have a concept of SDGs
// beyond the numerical ids
export const SDGList = () => {
  const sdgList = [
    {
      name: t`No Poverty`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-01.png",
      id: 1,
    },
    {
      name: t`Zero Hunger`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-02.png",
      id: 2,
    },
    {
      name: t`Good Health And Well-Being`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-03.png",
      id: 3,
    },
    {
      name: t`Quality Education`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-04.png",
      id: 4,
    },
    {
      name: t`Gender Equality`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-05.png",
      id: 5,
    },
    {
      name: t`Clean Water And Sanitation`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-06.png",
      id: 6,
    },
    {
      name: t`Affordable And Clean Energy`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-07.png",
      id: 7,
    },
    {
      name: t`Decent Work and Economic Growth`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-08.png",
      id: 8,
    },
    {
      name: t`Industry, Innovation And Infrastructure`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-09.png",
      id: 9,
    },
    {
      name: t`Reduced Inequalities`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-10.png",
      id: 10,
    },
    {
      name: t`Sustainable Cities And Communites`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-11.png",
      id: 11,
    },
    {
      name: t`Responsible Consumption And Production`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-12.png",
      id: 12,
    },
    {
      name: t`Climate Action`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-13.png",
      id: 13,
    },
    {
      name: t`Life Below Water`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-14.png",
      id: 14,
    },
    {
      name: t`Life On Land`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-15.png",
      id: 15,
    },
    {
      name: t`Peace, Justice And Strong Institutions`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-16.png",
      id: 16,
    },
    {
      name: t`Partnerships For The Goals`,
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-17.png",
      id: 17,
    },
  ]

  return sdgList.map((sdg) => {
    return {
      ...sdg,
      iconPath: `${V1_APP_URL}${sdg.iconPath}`,
    }
  })
}
