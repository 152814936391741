import { FormAnswerDto, FormQuestionDto } from "@src/api/form"
import { groupAndSortCurrencyAnswers } from "../utils"
import { SurveyType } from "@src/api/survey"
import { AnswersListContainer } from "../AnswersListContainer"
import { Table } from "@src/components/organisms/Table"
import { t } from "@lingui/macro"

export type AccountMoneyAnswersReportProps = {
  question: FormQuestionDto
  answers: Array<
    FormAnswerDto & {
      projectName?: string | null
      username?: string | null
      iterationName?: string | null
    }
  >
  surveyType: SurveyType
  reportType: "respondersAggregate" | "iterationAggregateForIndividual"
}

export const AccountMoneyAnswersReport = ({
  question,
  answers,
  surveyType,
  reportType,
}: AccountMoneyAnswersReportProps) => {
  const answersByQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question.id,
  )

  const data = groupAndSortCurrencyAnswers(
    question,
    answersByQuestion,
    surveyType,
    reportType,
  )

  const nameColumn =
    reportType === "respondersAggregate" ? t`Name` : t`Iteration`

  return (
    <AnswersListContainer className="px-4">
      {Object.keys(data).map((currency) => {
        const formattedRows = data[currency].map((row) => ({
          amount: `${row.amount} ${currency}`,
          name: row.name,
        }))

        return (
          <div className="mb-4" key={currency}>
            <Table
              columns={[
                { header: nameColumn, accessorKey: "name" },
                { header: t`Amount` + ` (${currency})`, accessorKey: "amount" },
              ]}
              data={formattedRows}
            />
          </div>
        )
      })}
    </AnswersListContainer>
  )
}
