import { ButtonLink } from "@src/components/atoms/Button/ButtonLink"
import { getFileNameFromPath, getAbsoluteFilePath } from "@src/utils/file"

export type FileAnswerProps = {
  filepath?: string | null
}

export const FileAnswer = ({ filepath }: FileAnswerProps) => {
  return (
    <ButtonLink
      variant="secondary"
      icon="download"
      small={true}
      href={getAbsoluteFilePath(filepath || "")}
      external={true}
    >
      {getFileNameFromPath(filepath || "")}
    </ButtonLink>
  )
}
