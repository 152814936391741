import { queryOptions } from "@tanstack/react-query"
import { safeQueryOptions } from "@src/utils/query"
import { client } from "./client"
import { components } from "./schema"
import { UnpackPromise, UnpackArray } from "@src/utils/types"

export const userKeys = {
  all: ["users"],
  current: () => [...userKeys.all, "current"],
  currentStuff: () => [...userKeys.current(), "stuff"],
  singleProfile: (id?: number) => [...userKeys.all, "profile", id],
  usersList: (id?: number) => [...userKeys.all, "list", "community", id],
}

export type LoginRequest = components["schemas"]["LoginRequestDto"]

export type TokenResponse = {
  access_token: string
  username: string
  token_type: string
}

export const getCurrentUser = async () => {
  const data = await client.GET("/api/User/GetCurrent")

  return data
}

export type CurrentUser = UnpackPromise<ReturnType<typeof getCurrentUser>>

export const getCurrentUserQuery = () =>
  queryOptions({
    queryKey: ["users", "current"],
    queryFn: getCurrentUser,
    // 24 hour cache time
    gcTime: 1000 * 60 * 60 * 24,
    // 1 minute stale time (time before background refresh)
    staleTime: 1000 * 60,
  })

export const getCurrentUserCommunities = async () => {
  const data = await client.GET("/api/User/GetMyCommunities")

  return data
}

export type UserCommunitiesList = UnpackPromise<
  ReturnType<typeof getCurrentUserCommunities>
>
export type UserCommunities = UnpackArray<UserCommunitiesList>

export const getCurrentUserCommunitiesQuery = () =>
  queryOptions({
    queryKey: ["users", "current", "communities"],
    queryFn: getCurrentUserCommunities,
  })

export const getToken = async (
  request: LoginRequest,
): Promise<TokenResponse> => {
  const data = await client.POST("/token", {
    body: request,
  })

  return data as unknown as TokenResponse
}

export const getCurrentUserStuff = async () => {
  const data = await client.GET("/api/User/GetMyStuff")

  return data
}

export type CurrentUserStuff = UnpackPromise<
  ReturnType<typeof getCurrentUserStuff>
>

export const getCurrentUserStuffQuery = () =>
  queryOptions({
    queryKey: userKeys.currentStuff(),
    queryFn: getCurrentUserStuff,
  })

export const getUserProfile = async (id: number) => {
  const data = await client.GET("/api/User/GetProfile", {
    query: {
      id,
    },
  })

  return data
}

export const getUserProfileQuery = (id?: number) =>
  safeQueryOptions({
    queryKeys: userKeys.singleProfile(id),
    queryFn: getUserProfile,
    unsafeQueryFnArgs: [id],
  })
