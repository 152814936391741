import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table"

import { Skeleton } from "@src/components/atoms/Skeleton"
import { DesktopTable } from "./DesktopTable"
import { MobileTable } from "./MobileTable"
import { Pagination } from "./Pagination"

export interface TableProps<T> {
  // TODO: check if we can improve the typing on this to allow us to use the
  // createColumnHelper util function from Tanstack table. Issue open here
  // https://github.com/TanStack/table/discussions/5218
  columns: ColumnDef<T>[]
  data: T[]
  itemsCount?: number
  pageIndex?: number
  pageSize?: number
  loading?: boolean
  onPageChange?: (pageIndex: number) => void
  onPageSizeChange?: (pageSize: number) => void
  pageSizeOptions?: number[]
  stickyColumn?: boolean
  forceDesktopView?: boolean
  sorting?: SortingState,
  onSortingChange?: (updaterOrValue: SortingState | ((old: SortingState) => SortingState)) => void
}

export const Table = <T extends object>({
  columns,
  data,
  itemsCount = 1,
  pageIndex = 1,
  pageSize = 10,
  loading = false,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions = [5, 10, 20, 50, 100],
  stickyColumn,
  forceDesktopView,
  sorting,
  onSortingChange,
}: TableProps<T>) => {
  const pageCount = Math.ceil(itemsCount / pageSize)
  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
      sorting: sorting
    },
    defaultColumn: {
      enableSorting: false
    },
    onSortingChange: onSortingChange,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  if (loading) {
    return (
      <div className="flex flex-col gap-1">
        <Skeleton className="h-12 w-full rounded-xl" />
        <Skeleton className="h-12 w-full rounded-xl" />
        <Skeleton className="h-12 w-full rounded-xl" />
        <Skeleton className="h-12 w-full rounded-xl" />
      </div>
    )
  }

  return (
    <div className="mx-auto w-full">
      {forceDesktopView ? (
        <DesktopTable table={table} stickyColumn={stickyColumn} />
      ) : (
        <>
          <div className="hidden lg:block">
            <DesktopTable table={table} stickyColumn={stickyColumn} />
          </div>
          <div className="lg:hidden">
            <MobileTable table={table} />
          </div>
        </>
      )}

      {pageCount > 1 && onPageSizeChange && onPageChange && (
        <Pagination
          pageIndex={pageIndex}
          itemsCount={itemsCount}
          pageSize={pageSize}
          handleFirstPage={() => onPageChange(0)}
          handleLastPage={() => onPageChange(pageCount - 1)}
          handlePreviousPage={() => {
            if (table.getCanPreviousPage()) {
              onPageChange(pageIndex - 1)
            }
          }}
          handleNextPage={() => {
            if (table.getCanNextPage()) {
              onPageChange(pageIndex + 1)
            }
          }}
          pageSizeOptions={pageSizeOptions}
          onPageSizeChange={onPageSizeChange}
        />
      )}
    </div>
  )
}
