import clsx from "clsx"
import { Icon } from "@src/components/atoms/Icon"
import { IconType } from "@src/config/icons"

export type AnswerProps = {
  title?: string | null
  className?: string
  icon?: IconType
  children: React.ReactNode
}

export const AnswerWrapper = ({
  className,
  icon,
  title,
  children,
}: AnswerProps) => {
  return (
    <div className={clsx(className, "pl-6")}>
      {title && (
        <div className="mb-2 flex gap-2">
          <Icon icon={icon || "arrow_forward"} size="large" />
          <span className="text-paragraph font-normal text-black">{title}</span>
        </div>
      )}
      <div className={clsx("text-black-light", title && "pl-7")}>
        {children}
      </div>
    </div>
  )
}
