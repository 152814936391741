import { useQuery } from "@tanstack/react-query"
import { Trans } from "@lingui/macro"
import {
  getSurveyResponseListQuery,
  getSurveyDefinitionQuery,
} from "@src/api/survey"
import { PageContainer } from "@src/components/atoms/PageContainer"
import { ReportingAnswers } from "@src/components/organisms/Answers/Reporting"
import { Skeleton } from "@src/components/atoms/Skeleton"

export type ReportingPageProps = {
  surveyId: number
}

export const ReportingPage = ({ surveyId }: ReportingPageProps) => {
  const { data: surveyDefinition, isLoading: definitionLoading } = useQuery(
    getSurveyDefinitionQuery(surveyId),
  )

  const { data: surveyResponsesList, isLoading: responsesListLoading } =
    useQuery(getSurveyResponseListQuery(surveyId, {}))

  const allAnswersList = surveyResponsesList?.flatMap(
    ({ answers, projectName, username }) => {
      return answers.map((answer) => {
        return { ...answer, projectName, username }
      })
    },
  )

  return (
    <>
      <h1 className="mb-6 text-heading5 font-bold text-black md:text-heading4">
        <Trans>Answers</Trans>
      </h1>
      {definitionLoading ||
        (responsesListLoading && <Skeleton className="h-[100vh]" />)}
      {surveyDefinition?.form && allAnswersList && (
        <PageContainer>
          <ReportingAnswers
            form={surveyDefinition?.form}
            answers={allAnswersList || []}
            surveyType={surveyDefinition.surveyType}
            reportType="respondersAggregate"
          />
        </PageContainer>
      )}
    </>
  )
}
