import { createFileRoute } from '@tanstack/react-router'
import { SurveyResponsePage } from '@src/components/pages/Surveys/Response'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/surveys/$surveyId/response/$responseId',
)({
  component: SurveyResponsePageWrapper,
})

function SurveyResponsePageWrapper() {
  const { surveyId, responseId } = Route.useParams()

  const parsedSurveyId = parseInt(surveyId)
  const parsedResponseId = parseInt(responseId)

  return (
    <SurveyResponsePage
      surveyId={parsedSurveyId}
      responseId={parsedResponseId}
    />
  )
}
