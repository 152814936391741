import { FormAnswerDto, FormQuestionDto } from "@src/api/form"
import { SurveyType } from "@src/api/survey"
import { AnswersListContainer } from "../AnswersListContainer"
import { AnswerWrapper } from "@src/components/molecules/Question/AnswerWrapper"
import { TableAnswer } from "@src/components/molecules/Question/TableAnswer"
import { formatTableReport } from "../utils"

export type TableAnswersReportProps = {
  question: FormQuestionDto
  answers: Array<
    FormAnswerDto & {
      projectName?: string | null
      username?: string | null
      iterationName?: string | null
    }
  >
  surveyType: SurveyType
  reportType: "respondersAggregate" | "iterationAggregateForIndividual"
}

export const TableAnswersReport = ({
  question,
  answers,
  surveyType,
  reportType,
}: TableAnswersReportProps) => {
  const answersByQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question.id,
  )

  const tableColumnPropsList = formatTableReport(
    question,
    answersByQuestion,
    surveyType,
    reportType,
  )

  const icon =
    reportType === "iterationAggregateForIndividual"
      ? "event_repeat"
      : surveyType === 1
        ? "groups"
        : "person"

  return (
    <AnswersListContainer>
      {tableColumnPropsList.map((tableColumnProps, index) => (
        <AnswerWrapper
          key={tableColumnProps.name}
          title={tableColumnProps.name}
          className="mb-4"
          icon={icon}
        >
          <TableAnswer key={index} {...tableColumnProps} className="mt-4" />
        </AnswerWrapper>
      ))}
    </AnswersListContainer>
  )
}
