import { FormAnswerDto, FormQuestionDto } from "@src/api/form"
import { SurveyType } from "@src/api/survey"
import { AnswersListContainer } from "../AnswersListContainer"
import { AnswerWrapper } from "@src/components/molecules/Question/AnswerWrapper"
import { FileAnswer } from "@src/components/molecules/Question/FileAnswer"

export type FileAnswersReportProps = {
  question: FormQuestionDto
  answers: Array<
    FormAnswerDto & {
      projectName?: string | null
      username?: string | null
      iterationName?: string | null
    }
  >
  surveyType: SurveyType
  reportType: "respondersAggregate" | "iterationAggregateForIndividual"
}

export const FileAnswersReport = ({
  question,
  answers,
  surveyType,
  reportType,
}: FileAnswersReportProps) => {
  const answersByQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question.id,
  )

  const icon =
    reportType === "iterationAggregateForIndividual"
      ? "event_repeat"
      : surveyType === 1
        ? "groups"
        : "person"

  return (
    <AnswersListContainer>
      {answersByQuestion.map((answer) => (
        <AnswerWrapper
          key={answer.id}
          title={
            reportType === "iterationAggregateForIndividual"
              ? answer?.iterationName
              : surveyType === 1
                ? answer?.projectName
                : answer?.username
          }
          className="mb-4"
          icon={icon}
        >
          <FileAnswer key={answer.id} filepath={answer.answer} />
        </AnswerWrapper>
      ))}
    </AnswersListContainer>
  )
}
