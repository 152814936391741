export const chartColors = [
  "#64C2A6",
  "#2D87BB",
  "#F66D44",
  "#FEAE65",
  "#E6F69D",
  "#AADEA7",
  "#F7B7A3",
  "#EA5F89",
  "#9B3192",
  "#57167E",
  "#2B0B3F",
  "#E6F69D",
  "#AADEA7",
  "#64C2A6",
  "#2D87BB",
  "#F47A1F",
  "#FDBB2F",
  "#377B2B",
  "#7AC142",
  "#007CC3",
  "#00529B",
]
