import { createFileRoute } from "@tanstack/react-router"
// import { z } from "zod"
import {
  SurveysResponsesPage,
  SurveyResponsesFilters,
} from "@src/components/pages/Surveys/Responses"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/programs/$programId/responses",
)({
  component: SurveyResponsesPageWrapper,
  validateSearch: () => ({}) as SurveyResponsesFilters,
})

function SurveyResponsesPageWrapper() {
  const { programId } = Route.useParams()

  // TODO: handle failing to parse surveyId
  const parsedProgramId = parseInt(programId)

  return <SurveysResponsesPage surveyId={parsedProgramId} />
}
