import { V1_APP_URL } from "./consts"

export const getFileNameFromPath = (path: string): string => {
  const parts = path.split("/")
  return parts[parts.length - 1]
}

export const getAbsoluteFilePath = (path: string): string => {
  if (path.startsWith("http")) return path

  if (path.startsWith("/")) {
    return `${V1_APP_URL}${path}`
  }

  return `${V1_APP_URL}/${path}`
}
