import { SDGList } from "@src/utils/consts"
import { Image } from "@src/components/atoms/Image"

export type SDGIconListProps = {
  sdgIds: number[]
}

export const SDGIconList = ({ sdgIds }: SDGIconListProps) => {
  return (
    <div className="flex flex-wrap gap-1">
      {sdgIds.map((sdgId) => (
        <Image
          key={sdgId}
          src={SDGList()[sdgId].iconPath}
          alt={SDGList()[sdgId].name}
          className="h-6 w-6"
          lazy={true}
        />
      ))}
    </div>
  )
}
