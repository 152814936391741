import { t } from "@lingui/macro"
import { FormQuestionDto, FormAnswerDto } from "@src/api/form"
import {
  TableAnswerProps,
  Column,
} from "@src/components/molecules/Question/TableAnswer"
import { ReportingAnswersProps } from "./Reporting"

// Given a list of answers, it groups them in an object by either projectName
// or username. So for each project or user, we have a list of all their answers.
// i.e. { projectName: [answers], projectName2: [answers2] }
export const groupByNameAnswers = (
  groupBy: "projectName" | "username" | "iterationName",
  answers: ReportingAnswersProps["answers"],
) =>
  answers.reduce(
    (acc, answer) => {
      const name = answer[groupBy]
      if (name) {
        if (!acc[name]) {
          acc[name] = []
        }
        acc[name].push(answer)
      }
      return acc
    },
    {} as Record<string, FormAnswerDto[]>,
  )

export const formatTableAnswer = (
  question: FormQuestionDto,
  answers: ReportingAnswersProps["answers"],
): TableAnswerProps => {
  const answersForQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question?.id,
  )

  const tableColumnProps: TableAnswerProps = {
    columns:
      question.questionOptions
        ?.sort((optionA, optionB) => {
          return (
            (optionA?.questionOptionOrder ?? 0) -
            (optionB?.questionOptionOrder ?? 0)
          )
        })
        .map((option) => {
          const answersForOption = answersForQuestion.filter(
            ({ optionAnswerOrder }) =>
              optionAnswerOrder === option.questionOptionOrder,
          )

          const orderedAnswersForColumn: string[] = answersForOption.reduce(
            (acc, answer) => {
              const answerIndex = answer.optionRowAnswerOrder

              if (answerIndex !== undefined && answerIndex !== null) {
                acc[answerIndex] = answer.answer || ""
              }
              return acc
            },
            [] as string[],
          )

          return {
            title: option.questionOption || "",
            id: option.id || 0,
            answers: orderedAnswersForColumn,
          }
        }) || [],
  }

  return tableColumnProps
}

export const numericReportToTableAnswer = (
  question: FormQuestionDto,
  answers: ReportingAnswersProps["answers"],
  surveyType: ReportingAnswersProps["surveyType"],
  reportType: "respondersAggregate" | "iterationAggregateForIndividual",
) => {
  const responderNameKey =
    reportType === "iterationAggregateForIndividual"
      ? "iterationName"
      : surveyType === 1
        ? "projectName"
        : "username"

  const nameColumnTitle =
    reportType === "iterationAggregateForIndividual"
      ? t`Iteration`
      : surveyType === 1
        ? t`Project`
        : t`Name`

  const nameColumn: Column = {
    title: nameColumnTitle,
    id: 1,
  }

  const nameAnswers: string[] = []

  const numericAnswersColumn: Column = {
    title: question.title || t`Value`,
    id: 2,
  }

  const numericAnswers: number[] = []

  answers.forEach((answer, index) => {
    nameAnswers[index] = answer[responderNameKey] || ""
    numericAnswers[index] = parseFloat(answer.answer || "0")
  })

  nameColumn.answers = nameAnswers
  numericAnswersColumn.answers = numericAnswers

  return [nameColumn, numericAnswersColumn]
}

export const formatTableReport = (
  question: FormQuestionDto,
  answers: ReportingAnswersProps["answers"],
  surveyType: ReportingAnswersProps["surveyType"],
  reportType: "respondersAggregate" | "iterationAggregateForIndividual",
) => {
  const groupBy =
    reportType === "iterationAggregateForIndividual"
      ? "iterationName"
      : surveyType === 1
        ? "projectName"
        : "username"

  const grouppedByNameAnswers = groupByNameAnswers(groupBy, answers)

  const tableColumnPropsList: Array<TableAnswerProps & { name: string }> = []

  Object.keys(grouppedByNameAnswers).forEach((name) => {
    const answersForName = grouppedByNameAnswers[name]

    const tableColumnProps = formatTableAnswer(question, answersForName)
    tableColumnPropsList.push({ ...tableColumnProps, name })
  })

  return tableColumnPropsList
}

export const createOptionAnswersPieChartReport = (
  question: FormQuestionDto,
  answers: ReportingAnswersProps["answers"],
) => {
  const answersForQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question?.id,
  )

  const optionResponsesCount = question.questionOptions?.map((option) => {
    const answersForOption = answersForQuestion.filter(
      ({ optionAnswerOrder }) =>
        optionAnswerOrder === option.questionOptionOrder,
    )

    const optionCount = answersForOption.length

    return {
      name: option.questionOption || "",
      value: optionCount,
    }
  })

  return optionResponsesCount || []
}

export const groupAndSortCurrencyAnswers = (
  question: FormQuestionDto,
  answers: ReportingAnswersProps["answers"],
  surveyType: ReportingAnswersProps["surveyType"],
  reportType: "respondersAggregate" | "iterationAggregateForIndividual",
) => {
  const answersForQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question?.id,
  )

  const groupedByCurrency: Record<string, { amount: number; name: string }[]> =
    {}

  answersForQuestion.forEach((answer) => {
    const match = answer.answer?.match(/^(\d+)([A-Z]{3})$/)
    if (match) {
      const amount = parseFloat(match[1])
      const currency = match[2]
      const name =
        reportType === "iterationAggregateForIndividual"
          ? answer?.iterationName
          : surveyType === 1
            ? answer?.projectName
            : answer?.username

      if (!groupedByCurrency[currency]) {
        groupedByCurrency[currency] = []
      }

      groupedByCurrency[currency].push({ amount, name: name || "" })
    }
  })

  Object.keys(groupedByCurrency).forEach((currency) => {
    groupedByCurrency[currency].sort((a, b) => b.amount - a.amount)
  })

  return groupedByCurrency
}
