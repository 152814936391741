import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import clsx from "clsx"
import "./SimpleBarChart.css"

export type SimpleBarChartProps = {
  className?: string
  height?: number
  width?: number
  data: {
    name: string
    value: number
  }[]
  color?: string
}

export const SimpleBarChart = ({
  data,
  className,
  height = 300,
  width = 600,
}: SimpleBarChartProps) => {
  return (
    <ResponsiveContainer
      width="100%"
      minWidth={width}
      height={height}
      className={clsx(
        "hide-text overflow-auto text-paragraph-small md:text-paragraph-medium",
        className,
      )}
    >
      <BarChart
        height={height}
        width={width}
        data={data}
        className={clsx(className)}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" fill="#355F93" />
      </BarChart>
    </ResponsiveContainer>
  )
}
