import { clsx } from "clsx"
import { SDGIconList } from "../SDG/SDGIconList"
import { PageContainer } from "@src/components/atoms/PageContainer"
import { Icon } from "@src/components/atoms/Icon"

export type ProjectHeaderProps = {
  city?: string | null
  countryName?: string | null
  name?: string | null
  sdgIds?: number[] | null
  categoryName?: string | null
  className?: string
}

export const ResponseHeaderProject = ({
  city,
  countryName,
  name,
  sdgIds,
  categoryName,
  className,
}: ProjectHeaderProps) => {
  return (
    <PageContainer className={clsx("mb-6", className)}>
      <h2 className="mb-2 text-2xl text-pearl-dark">{name}</h2>
      <div className="h-[1px] bg-pearl-lighter"></div>
      <div className="flex text-paragraph-medium">
        <div>
          <div className="mb-2 mt-3 flex">
            <Icon icon="pin_drop" className="mr-1 text-blue" />
            {city && <span className="mr-1 text-pearl-dark">{city},</span>}
            {countryName && (
              <span className="text-pearl-dark">{countryName}</span>
            )}
          </div>
          <div className="flex">
            <Icon icon="stacks" className="mr-1 text-blue" />
            {categoryName && (
              <span className="text-pearl-dark">{categoryName}</span>
            )}
          </div>
        </div>
        {sdgIds && (
          <div className="ml-auto flex items-center">
            <SDGIconList sdgIds={sdgIds} />
          </div>
        )}
      </div>
    </PageContainer>
  )
}
