import { TableAnswerProps } from "./TableAnswer"

export const columnsToRows = (columns: TableAnswerProps["columns"]) => {
  const firstColumn = columns[0]

  if (!firstColumn?.answers) {
    return []
  }

  const rows: Record<number, string | number | null>[] = []

  for (let rowIndex = 0; rowIndex < firstColumn.answers.length; rowIndex++) {
    const row = {} as Record<number, string | number | null>

    columns.forEach((column) => {
      row[column.id] = column.answers?.[rowIndex] ?? null
    })

    rows.push(row)
  }

  return rows
}
