import { useState } from "react"
import {
  Combobox as HeadlessCombobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react"
import clsx from "clsx"

import { Field, Label } from "@src/components/atoms/Fieldset"
import { Icon } from "@src/components/atoms/Icon"
import {
  commonStyles,
  FieldProps,
  OptionProps,
} from "@src/components/atoms/Fieldset/common"

export type ComboboxProps<T extends number | string> = FieldProps & {
  options: OptionProps<T>[]
  value?: OptionProps<T> | null
  onSelect: (option: OptionProps<T>) => void
}

export const Combobox = <T extends string | number>({
  variant = "primary",
  options,
  label,
  description,
  placeholder = "",
  value,
  small = false,
  className,
  required = false,
  onSelect,
  onBlur,
  disabled,
}: ComboboxProps<T>) => {
  const [query, setQuery] = useState("")

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase()),
        )

  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={label} required={required} description={description}>
        {label}
      </Label>
      <HeadlessCombobox
        value={value}
        virtual={{ options: filteredOptions }}
        onChange={onSelect}
        disabled={disabled}
        onClose={() => setQuery("")}
      >
        <div className="relative">
          <ComboboxInput
            className={clsx(
              commonStyles.base,
              commonStyles[variant],
              small ? ["gap-2 py-1 pl-3 pr-1"] : ["gap-4 py-2 pl-5 pr-2"],
            )}
            placeholder={placeholder}
            displayValue={(option: OptionProps<T>) => option?.label ?? ""}
            onChange={(event) => setQuery(event.target.value)}
          />
          <ComboboxButton className="group absolute inset-y-0 right-0 flex items-center px-2.5">
            <Icon
              className="group pointer-events-none text-black"
              icon="keyboard_arrow_down"
              size="large"
            />
          </ComboboxButton>
          <ComboboxOptions
            anchor="bottom"
            transition
            className={clsx(commonStyles.options, "!max-h-60 !w-[var(--input-width)]")}
            onBlur={onBlur}
          >
            {({ option }) => (
              <ComboboxOption
                key={option.id}
                value={option}
                className={clsx(
                  commonStyles.option,
                  option.id === value?.id && "bg-blue-light/50",
                )}
              >
                {option.icon && (
                  <Icon
                    className="text-black"
                    icon={option.icon}
                    size="large"
                  />
                )}
                {option.label}
              </ComboboxOption>
            )}
          </ComboboxOptions>
        </div>
      </HeadlessCombobox>
    </Field>
  )
}
