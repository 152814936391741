import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/programs/$programId/planner",
)({
  component: ProgramPlannerWrapper,
})

function ProgramPlannerWrapper() {
  const { programId } = Route.useParams()

  // TODO: handle failing to parse surveyId
  const parsedProgramId = parseInt(programId)

  return `Planner for ${parsedProgramId}`
}
