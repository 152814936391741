import { RadioButtonList } from "@src/components/atoms/RadioButtonList"
import { FormAnswerDto, FormQuestionDto } from "@src/api/form"

export type RadioButtonAnswerProps = {
  question: FormQuestionDto
  answer: FormAnswerDto
}

export const RadioButtonAnswer = ({
  question,
  answer,
}: RadioButtonAnswerProps) => {
  const radioOptions = question?.questionOptions?.map((option) => ({
    id: option.questionOptionOrder || 0,
    label: option.questionOption || "",
  }))

  const selectedOption = answer.optionAnswerOrder

  if (!radioOptions?.length) {
    return <div></div>
  }

  // We don't need an onChange, but it's required by the component.
  // Not using an empty function as it cannot be covered in the tests
  // coverage report.
  return (
    <RadioButtonList
      options={radioOptions}
      value={selectedOption}
      className="pointer-events-none"
      onChange={undefined as unknown as () => unknown}
    />
  )
}
