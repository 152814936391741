import {
  ar, bg, de, enUS, enGB, es, fr, el, hr, it,
  pt, ptBR, ro, ru, sr, tr, zhCN, zhTW,
} from "date-fns/locale"
import { Locale } from "date-fns"

const localesMap: Record<string, Locale> = {
  ar,
  bg,
  de,
  "en": enUS,
  "en-us": enUS,
  "en-gb": enGB,
  es,
  fr,
  el,
  hr,
  it,
  pt,
  "pt-br": ptBR,
  ro,
  ru,
  sr,
  tr,
  "zh": zhCN,
  "zh-cn": zhCN,
  "zh-tw": zhTW,
} as const

export const mapLanguageToDateFnsLocale = (languageString: string): Locale => {
  const normalizedToLower = languageString.toLowerCase()

  if (localesMap[normalizedToLower]) return localesMap[normalizedToLower]

  const baseLanguage = languageString.slice(0,2)
  if(localesMap[baseLanguage]) return localesMap[baseLanguage]

  return enUS
}
