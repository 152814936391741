import clsx from "clsx"

export type AnswersListContainerProps = {
  children: React.ReactNode
  className?: string
  scrollable?: boolean
}

export const AnswersListContainer = ({
  children,
  className,
  scrollable = true,
}: AnswersListContainerProps) => {
  return (
    <div
      className={clsx(
        className,
        "max-h-[600px] rounded-xl bg-ghost-white pr-4 pt-4",
        scrollable && "overflow-auto",
      )}
    >
      {children}
    </div>
  )
}
