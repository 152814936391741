import { useQuery } from "@tanstack/react-query"
import { Trans } from "@lingui/macro"
import { FormAnswerDto, FormQuestionDto } from "@src/api/form"
import { SurveyType } from "@src/api/survey"
import { TextAnswer } from "@src/components/molecules/Question/TextAnswer"
import { AnswersListContainer } from "../AnswersListContainer"
import { AnswerWrapper } from "@src/components/molecules/Question/AnswerWrapper"
import { Skeleton } from "@src/components/atoms/Skeleton"
import { getCountriesQuery } from "@src/api/country"

export type CountryAnswersReportProps = {
  question: FormQuestionDto
  answers: Array<
    FormAnswerDto & {
      projectName?: string | null
      username?: string | null
      iterationName?: string | null
    }
  >
  surveyType: SurveyType
  reportType: "respondersAggregate" | "iterationAggregateForIndividual"
}

export const CountryAnswersReport = ({
  question,
  answers,
  surveyType,
  reportType,
}: CountryAnswersReportProps) => {
  const { data: countries, isLoading, isError } = useQuery(getCountriesQuery())

  const answersByQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question.id,
  )

  if (isLoading) {
    return <Skeleton className="h-[400px]" />
  }

  if (isError) {
    return (
      <AnswersListContainer>
        <Trans>
          An unexpected error happened, try refreshing the page. If the problem
          persists, please contact support.
        </Trans>
      </AnswersListContainer>
    )
  }

  const icon =
    reportType === "iterationAggregateForIndividual"
      ? "event_repeat"
      : surveyType === 1
        ? "groups"
        : "person"

  const answersWithCountry = answersByQuestion.map((answer) => {
    const country = countries?.find(
      (country) => country.id === answer.optionAnswerOrder,
    )

    return {
      ...answer,
      answer: country?.name,
    }
  })

  return (
    <AnswersListContainer>
      {answersWithCountry.map((answer) => (
        <AnswerWrapper
          key={answer.id}
          title={
            reportType === "iterationAggregateForIndividual"
              ? answer?.iterationName
              : surveyType === 1
                ? answer?.projectName
                : answer?.username
          }
          className="mb-4"
          icon={icon}
        >
          <TextAnswer key={answer.id} answer={answer.answer} />
        </AnswerWrapper>
      ))}
    </AnswersListContainer>
  )
}
