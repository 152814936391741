import { FormAnswerDto, FormDto } from "@src/api/form"
import { Question } from "@src/components/molecules/Question"
import { FormQuestionAnswerType } from "@src/api/form"
import { SurveyType } from "@src/api/survey"
import { AccountMoneyAnswersReport } from "./Reporting/AccountMoneyAnswersReport"
import { LongTextAnswersReport } from "./Reporting/LongTextAnswersReport"
import { ShortTextAnswersReport } from "./Reporting/ShortTextAnswersReport"
import { FileAnswersReport } from "./Reporting/FileAnswersReport"
import { TableAnswersReport } from "./Reporting/TableAnswersReport"
import { OptionsChoiceAnswersReport } from "./Reporting/OptionChoiceAnswersReport"
import { CalendarAnswersReport } from "./Reporting/CalendarAnswersReport"
import { NumericAnswersReport } from "./Reporting/NumericAnswersReport"
import { CountryAnswersReport } from "./Reporting/CountryAnswersReport"

export type ReportingAnswersProps = {
  form: FormDto
  answers: Array<
    FormAnswerDto & {
      projectName?: string | null
      username?: string | null
      iterationName?: string | null
    }
  >
  surveyType: SurveyType
  /* respondersAggregate: combines the responses of multiple projects or
     people for a survey iteration
     iterationAggregateForIndividual: combines the responses of multiple iterations
     for the same project or person
  */
  reportType: "respondersAggregate" | "iterationAggregateForIndividual"
}

export const ReportingAnswers = ({
  form,
  answers,
  surveyType,
  reportType,
}: ReportingAnswersProps) => {
  // TODO need to order the questions by the order field
  const questions = form?.questions?.map((question) => {
    const answersByQuestion = answers.filter(
      (answer) => answer?.formQuestionId === question.id,
    )

    let answerElements: React.ReactNode[] | React.ReactNode = []
    switch (question.answerType) {
      case FormQuestionAnswerType.ShortTextAnswer: {
        answerElements = (
          <ShortTextAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.LongTextAnswer: {
        answerElements = (
          <LongTextAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )

        break
      }
      case FormQuestionAnswerType.FileAnswer: {
        answerElements = (
          <FileAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.TableAnswer: {
        answerElements = (
          <TableAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )

        break
      }
      case FormQuestionAnswerType.NumericAnswer: {
        answerElements = (
          <NumericAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.RadioChoiceAnswer: {
        answerElements = (
          <OptionsChoiceAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.CheckBoxChoiceAnswer: {
        answerElements = (
          <OptionsChoiceAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.MultipleChoiceAnswer: {
        answerElements = (
          <OptionsChoiceAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.DropDownChoiceAnswer: {
        answerElements = (
          <OptionsChoiceAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.ScaleAnswer: {
        answerElements = (
          <OptionsChoiceAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.AccountMoneyAnswer: {
        answerElements = (
          <AccountMoneyAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.CalendarAnswer: {
        answerElements = (
          <CalendarAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }
      case FormQuestionAnswerType.CountryAnswer: {
        answerElements = (
          <CountryAnswersReport
            question={question}
            answers={answersByQuestion}
            surveyType={surveyType}
            reportType={reportType}
          />
        )
        break
      }

      default:
        return null
    }

    return (
      <Question
        key={question.id}
        question={question}
        children={answerElements}
      />
    )
  })

  return <div>{questions}</div>
}
