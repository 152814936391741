import { Link, LinkProps } from "@tanstack/react-router"
import { clsx } from "clsx"

import { Icon } from "@src/components/atoms/Icon"

import { styles, BaseButtonProps } from "./common"

export type ButtonProps = BaseButtonProps &
  LinkProps & { external?: boolean; href?: string }

export const ButtonLink = ({
  variant = "primary",
  className,
  icon,
  small = false,
  children,
  external = false,
  ...props
}: ButtonProps) => {
  const classes = clsx(
    className,
    styles.base,
    styles[variant],
    styles.size(small),
  )

  return external ? (
    <a className={classes} {...props}>
      {icon && <Icon size={small ? "default" : "large"} icon={icon} />}

      {children}
    </a>
  ) : (
    <Link className={classes} {...props}>
      {icon && <Icon size={small ? "default" : "large"} icon={icon} />}

      {children}
    </Link>
  )
}
