export type FileAnswerProps = {
  html?: string | null
}

// Long question answer
export const RichTextAnswer = ({ html }: FileAnswerProps) => {
  return (
    <div
      className="text-paragraph-medium text-black-light"
      dangerouslySetInnerHTML={{ __html: html || "" }}
    />
  )
}
