import { safeQueryOptions } from "@src/utils/query"
import { client } from "./client"
import { UnpackPromise } from "@src/utils/types"

export const projectKeys = {
  all: ["projects"],
  singleOverview: (id?: number) => [...projectKeys.all, "overview", id],
}

export const getProjectOverview = async (projectId: number) => {
  const data = await client.GET("/api/Project/GetOverview", {
    params: {
      query: { id: projectId },
    },
  })

  return data
}

export type ProjectOverview = UnpackPromise<
  ReturnType<typeof getProjectOverview>
>

export const getProjectOverviewQuery = (projectId?: number) =>
  safeQueryOptions({
    queryFn: getProjectOverview,
    queryKeys: projectKeys.singleOverview(projectId),
    unsafeQueryFnArgs: [projectId],
  })
