import { format } from "date-fns"
import { enUS } from "date-fns/locale"
import { FormAnswerDto, FormQuestionDto } from "@src/api/form"
import { SurveyType } from "@src/api/survey"
import { AnswersListContainer } from "../AnswersListContainer"
import { AnswerWrapper } from "@src/components/molecules/Question/AnswerWrapper"
import { TextAnswer } from "@src/components/molecules/Question/TextAnswer"

const userLocale = navigator.language || "en-US"

export type CalendarAnswersReportProps = {
  question: FormQuestionDto
  answers: Array<
    FormAnswerDto & {
      projectName?: string | null
      username?: string | null
      iterationName?: string | null
    }
  >
  surveyType: SurveyType
  reportType: "respondersAggregate" | "iterationAggregateForIndividual"
}

export const CalendarAnswersReport = ({
  question,
  answers,
  surveyType,
  reportType,
}: CalendarAnswersReportProps) => {
  const answersByQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question.id,
  )

  const icon =
    reportType === "iterationAggregateForIndividual"
      ? "event_repeat"
      : surveyType === 1
        ? "groups"
        : "person"

  return (
    <AnswersListContainer>
      {answersByQuestion.map((answer) => {
        if (!answer.answer) {
          return null
        }

        const formattedDate = format(new Date(answer.answer), "P", {
          locale: userLocale === "en-US" ? enUS : undefined,
        })

        return (
          <AnswerWrapper
            key={answer.id}
            title={
              reportType === "iterationAggregateForIndividual"
                ? answer?.iterationName
                : surveyType === 1
                  ? answer?.projectName
                  : answer?.username
            }
            className="mb-4"
            icon={icon}
          >
            <TextAnswer key={answer.id} answer={formattedDate} />
          </AnswerWrapper>
        )
      })}
    </AnswersListContainer>
  )
}
