import { Link } from "@tanstack/react-router"
import { clsx } from "clsx"

import { Icon } from "@src/components/atoms/Icon"
import { IconType } from "@src/config/icons"
import "./MenuItem.css"

export type MenuItemProps = {
  name?: string
  link: string
  icon: IconType
  className?: string
  notification?: boolean
  children?: {
    name: string
    link: string
    icon: IconType
  }[]
}

export const MenuItem = ({
  name,
  link,
  icon,
  className,
  notification = false,
  children,
}: MenuItemProps) => {
  return (
    <div>
      <Link
        className={clsx([
          className,
          "flex w-full items-center gap-4",

          "rounded-lg",

          "hover:bg-blue-light/50 hover:text-blue",
        ])}
        activeProps={{
          className: "bg-blue-light/50 text-blue item-active",
        }}
        activeOptions={{ exact: false }}
        inactiveProps={{
          className: "text-pearl-dark",
        }}
        to={link}
      >
        {icon && <Icon size="large" icon={icon} />}

        {name}

        {notification && (
          <span role="status" className="h-2 w-2 rounded-full bg-red"></span>
        )}
      </Link>
      <div className="children pl-8">
        {children?.length && children.length > 0
          ? children.map(({ name, link, icon }) => (
              <Link
                className={clsx([
                  className,
                  "flex w-full items-center gap-4",

                  "rounded-lg",

                  "text-paragraph-medium hover:bg-blue-light/50 hover:text-blue",
                ])}
                to={link}
                key={name}
                activeProps={{
                  className: "bg-blue-light/50 text-blue item-active",
                }}
                activeOptions={{ exact: true }}
                inactiveProps={{
                  className: "text-pearl-dark",
                }}
              >
                {icon && <Icon size="large" icon={icon} />}
                {name}
              </Link>
            ))
          : null}
      </div>
    </div>
  )
}
