import { createFileRoute } from '@tanstack/react-router'
import { Coaching } from '@src/components/pages/Coaching'


export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/requests-status/',
)({
  component: CoachingPageWrapper,
})

function CoachingPageWrapper() {
  const { communityId } = Route.useParams()
  const parsedCommunityId = parseInt(communityId)

  return (<Coaching communityId={parsedCommunityId}/>)
}


