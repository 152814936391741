// import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import {
  getSurveyDefinition_SuccessResponse,
  getSurveyResponse_SuccessResponse,
  getSurveyDefinitionList_SuccessResponse,
  getSurveyResponseList_SuccessResponse,
} from "./survey.msw"
import { components } from "./schema"
import { ApiDynamicFilter } from "./filters"
import { FormDto } from "./form"

export const surveyKeys = {
  all: ["surveys"],
  surveyDefinitions: (communityId?: number, search?: string) => [
    ...surveyKeys.all,
    "list",
    communityId,
    search,
  ],
  surveyDefinition: (id?: number) => [...surveyKeys.all, "definition", id],
  surveyResponse: (id?: number) => [...surveyKeys.all, "response", id],
  surveyResponsesList: (id: number, filters?: SurveyResponsesFilters) => [
    ...surveyKeys.all,
    "responsesList",
    id,
    filters,
  ],
}

export enum SurveyType {
  Startup = 1,
  Individual = 2,
}

export enum RecurrenceType {
  Once = 0,
  OncePerWeek = 1,
  OncePerMonth = 2,
  OncePerQuarter = 3,
  OncePerYear = 4,
}

export type SurveyDefinition = {
  id: number
  title: string
  description: string
  surveyType: SurveyType
  communityId: number
  formId: number
  recurrence: RecurrenceType
  form?: FormDto
  recurrency: number
  KPIConvert: boolean
  startDate: Date
  startDateTimezoneCode: string
  endDate: Date
  endDateTimezoneCode: string
  circleList: number[]
  tags: string[]
  methodologyList: number[]
  deadline?: Date
}

export type FormAnswerDto = components["schemas"]["FormAnswerDto"]

export enum SurveyStatus {
  Draft = 0,
  Submitted = 1,
}

enum SurveyOrderBy {
  Status,
  FillRate,
}

export type SurveyResponsesFilters = {
  iterationFilter?: ApiDynamicFilter<number>
  statusFilter?: ApiDynamicFilter<SurveyStatus>
  userFilter?: ApiDynamicFilter<number>
  projectFilter?: ApiDynamicFilter<number>
  orderBy?: SurveyOrderBy
  skip?: number
  take?: number
}

export const getSurveyDefinition = async (id: number) => {
  const data = await Promise.resolve(getSurveyDefinition_SuccessResponse(id))

  return data
}

export const getSurveyDefinitionQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getSurveyDefinition,
    queryKeys: surveyKeys.surveyDefinition(),
    unsafeQueryFnArgs: [id],
  })

export const getSurveyDefinitionList = async (
  communityId?: number,
  search?: string,
) => {
  const data = await Promise.resolve(
    getSurveyDefinitionList_SuccessResponse(communityId || 1),
  )
  if (!search) {
    return data
  }

  return data.filter(({ title }) => title.includes(search))
}

export const getSurveyDefinitionListQuery = (
  communityId?: number,
  search?: string,
) =>
  safeQueryOptions({
    queryFn: getSurveyDefinitionList,
    queryKeys: surveyKeys.surveyDefinitions(communityId, search),
    unsafeQueryFnArgs: [communityId, search],
  })

export const getSurveyResponse = async (id: number) => {
  const data = await Promise.resolve(getSurveyResponse_SuccessResponse(id))

  return data
}

export const saveSurveyDefinition = async (
  payload: SurveyDefinition,
  id?: number,
) => {
  console.log("saveSurveyDefinition", id, payload)
  return await Promise.resolve({ id })
}

export const deleteSurveyDefinition = async (id?: number) => {
  console.log("deleteSurveyDefinition", id)
  return await Promise.resolve({})
}

export const getSurveyResponseQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getSurveyResponse,
    queryKeys: surveyKeys.surveyResponse(),
    unsafeQueryFnArgs: [id],
  })

export const submitSurvey = async (payload: {
  id: number
  status: SurveyStatus
  answers: FormAnswerDto[]
}) => {
  console.log("wtf payload", payload)
  const data = await Promise.resolve({})

  return data
}

export type SurveyResponse = {
  id: number
  answers: FormAnswerDto[]
  userId: number
  status: SurveyStatus
  tags: string[]
  iteration: number
  evaluators: string[]
  evaluation: number
  username?: string | null
  projectName?: string | null
  projectId?: number | null
}

export const getSurveyResponseList = async (
  surveyId: number,
  surveyFilters?: SurveyResponsesFilters,
): Promise<SurveyResponse[]> => {
  console.log(surveyId, surveyFilters)
  const data = await Promise.resolve(getSurveyResponseList_SuccessResponse())

  return data
}

export const getSurveyResponseListQuery = (
  surveyId: number,
  surveyFilters?: SurveyResponsesFilters,
) =>
  safeQueryOptions({
    queryFn: getSurveyResponseList,
    queryKeys: surveyKeys.all,
    unsafeQueryFnArgs: [surveyId, surveyFilters],
  })
