import { createFileRoute } from '@tanstack/react-router'
import { SurveyResponsesFilters } from '@src/api/survey'
import { ReportingPage } from '@src/components/pages/Surveys/Reporting'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/surveys/$surveyId/reporting',
)({
  component: SurveyReportingPageWrapper,
  // TODO: parse search params
  validateSearch: () => ({}) as SurveyResponsesFilters,
})

function SurveyReportingPageWrapper() {
  const { surveyId } = Route.useParams()

  const parsedSurveyId = parseInt(surveyId)

  return <ReportingPage surveyId={parsedSurveyId} />
}
