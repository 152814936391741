import { useEffect } from "react"
import { getRouteApi } from "@tanstack/react-router"
import clsx from "clsx"
import { Trans } from "@lingui/macro"
import { OptionProps } from "@src/components/atoms/Fieldset/common"
import { Select } from "@src/components/atoms/Select"
import { Button } from "@src/components/atoms/Button"
import { MultiSelect } from "@src/components/atoms/MultiSelect"
import { UnpackArray } from "@src/utils/types"
import { Combobox } from "@src/components/atoms/Combobox"
import { ApiDynamicFilterType } from "@src/api/filters"
import { cleanClientFilters } from "./utils"
import { DatePicker } from "@src/components/atoms/DatePicker"

export type DynamicFilter<T extends string | number> = {
  filterType?: ApiDynamicFilterType
  values?: T[] | null
}

export type ClientFilters = {
  [key: string]:
    | string
    | number
    | null
    | undefined
    | string[]
    | number[]
    | DynamicFilter<string | number>
    | Date
}

export type ClientFilterOptions<T extends ClientFilters> = {
  [K in keyof T]:
    | {
        type: "select"
        options: T[K] extends string | number | undefined
          ? OptionProps<Extract<T[K], string | number>>[]
          : never
        label: string
      }
    | {
        type: "multiSelect"
        options: T[K] extends string[] | number[] | undefined
          ? OptionProps<Extract<UnpackArray<T[K]>, string | number>>[]
          : never
        label: string
      }
    | {
        type: "combobox"
        options: T[K] extends string | number | undefined
          ? OptionProps<Extract<T[K], string | number>>[]
          : never
        label: string
      }
    | {
        type: "multiSelectAdvanced"
        options: OptionProps<number | string>[]
        label: string
      }
    | {
        type: "date"
        options?: T[K] extends Date | null | undefined ? undefined : never
        label: string
      }
}

// Not the best solution, but it works for now to provide typesafety for the filters & search params
// This is basically a list of all the routes that have search params of type ClientFilters (or an extension of it)
// A better solution would be to infer this from the router type. A type that select all the routes from the Router
// which have search params of type ClientFilters. A starting point for this would be this, which is the type of the routes:
// T extends RouteIds<RegisteredRouter['routeTree']>
export type RoutesWithClientFilters =
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/requests-status/"

export type ClientFiltersProps<T extends ClientFilters> = {
  values: T
  options: ClientFilterOptions<T>
  defaultValues?: Partial<T>
  onChange: (values: T) => void
  className?: string
  showLabels?: boolean
  showResetButton?: boolean
  route: RoutesWithClientFilters
}

export const ClientFilters = <T extends ClientFilters>({
  defaultValues = {} as Partial<T>,
  values,
  options,
  onChange,
  route,
  className,
  showLabels = true,
  showResetButton = false,
}: ClientFiltersProps<T>) => {
  const routeApi = getRouteApi(route)
  const filters: T = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const setFilters = (newFilters: T) => {
    navigate({
      search: () => cleanClientFilters(newFilters),
    })
  }

  useEffect(() => {
    if (filters) {
      onChange(filters)
    }
  }, [filters, onChange])

  return (
    <div className={clsx(
      "grid gap-2 grid-cols-1 sm:grid-cols-2",
      "md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
      className
    )}>
      {Object.keys(options).map((key) => {
        const option = options[key]
        const value = values[key]

        switch (option.type) {
          case "select":
            return (
              <Select
                key={key}
                label={showLabels ? option.label : undefined}
                options={option.options}
                placeholder={option.label}
                value={option.options.find((opt) => opt.id === value) || null}
                onSelect={(selectedOption) => {
                  setFilters({
                    ...filters,
                    [key]: selectedOption.id,
                  })
                }}
              />
            )
          case "multiSelect": {
            const safeValues = value as (string | number)[] | null | undefined
            return (
              <MultiSelect
                key={key}
                className="shrink-0"
                label={showLabels ? option.label : undefined}
                options={option.options}
                placeholder={option.label}
                value={
                  option.options.filter((opt) =>
                    safeValues?.includes(opt.id),
                  ) || []
                }
                onSelect={(selectedOptions) => {
                  const newValues = option.options
                    ?.filter((o) =>
                      selectedOptions.find((so) => so.id === o.id),
                    )
                    ?.map((o) => o.id)
                  setFilters({
                    ...filters,
                    [key]: newValues,
                  })
                }}
              />
            )
          }
          case "combobox":
            return (
              <Combobox
                key={key}
                label={showLabels ? option.label : undefined}
                options={option.options}
                placeholder={option.label}
                value={option.options.find((opt) => opt.id === value) || null}
                onSelect={(selectedOption) => {
                  setFilters({
                    ...filters,
                    [key]: selectedOption.id,
                  })
                }}
              />
            )
          case "multiSelectAdvanced": {
            const safeValue = value as
              | DynamicFilter<string | number>
              | null
              | undefined

            console.log(safeValue)

            return (
              <MultiSelect
                key={key}
                className="shrink-0"
                label={showLabels ? option.label : undefined}
                options={option.options}
                placeholder={option.label}
                value={
                  option.options.filter((opt) =>
                    safeValue?.values?.includes(opt.id),
                  ) || []
                }
                onSelect={(selectedOptions) => {
                  const newValues = option.options
                    ?.filter((o) =>
                      selectedOptions.find((so) => so.id === o.id),
                    )
                    ?.map((o) => o.id)

                  setFilters({
                    ...filters,
                    [key]: {
                      values: newValues,
                      filterType:
                        safeValue?.filterType || ApiDynamicFilterType.IsAnyOf,
                    },
                  })
                }}
                advancedFilterValue={
                  safeValue?.filterType || ApiDynamicFilterType.IsAnyOf
                }
                onFilterChange={(advancedFilterOption) => {
                  setFilters({
                    ...filters,
                    [key]: {
                      values: safeValue?.values,
                      filterType: advancedFilterOption.id,
                    },
                  })
                }}
              />
            )
          }

          case "date": {
            const safeValue = value as Date | null | undefined
            return (
              <DatePicker
                name={key}
                key={key}
                label={showLabels ? option.label : undefined}
                value={safeValue || null}
                hasTimePicker={false}
                onChange={(date) => {
                  setFilters({
                    ...filters,
                    [key]: date,
                  })
                }}
              />
            )
          }
        }
      })}

      {showResetButton && (
        <div className="flex shrink-0">
          <Button
            variant="secondary"
            className="h-[38px] self-end"
            onClick={() => {
              setFilters(defaultValues as T)
            }}
          >
            <Trans>Reset filters</Trans>
          </Button>
        </div>
      )}
    </div>
  )
}
