import { queryOptions } from "@tanstack/react-query"
import { client } from "./client"
import { UnpackPromise, UnpackArray } from "@src/utils/types"

export type Country = {
  id: number
  name: string | null
  code: string | null
  timezone: string | null
}

export const countryKeys = {
  all: ["countries"],
  countryList: (loadGlobalOption: boolean) => [
    ...countryKeys.all,
    "list",
    loadGlobalOption,
  ],
}

export const getCountries = async (loadGlobalOption: boolean) => {
  const data = await client.GET("/api/Country/GetAll", {
    params: {
      query: {
        loadGlobalOption,
      },
    },
  })

  // The swagger file doesn't have the correct type for this endpoint,
  // so we have to cast it to the correct type
  return data as unknown as Country[]
}

export type CommunityCircleDetail = UnpackArray<
  UnpackPromise<ReturnType<typeof getCountries>>
>

export const getCountriesQuery = (loadGlobalOption: boolean = false) =>
  queryOptions({
    queryFn: () => getCountries(loadGlobalOption),
    queryKey: countryKeys.countryList(loadGlobalOption),
    // 24 hour cache time
    gcTime: 1000 * 60 * 60 * 24,
    // 24 hour cache timestale time (time before background refresh)
    // This essentially never changes, so we can cache it for a long time
    staleTime: 1000 * 60 * 60 * 24,
    retry: false,
  })
