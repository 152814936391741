import {
  Switch as HeadlessSwitch,
  type SwitchProps as HeadlessSwitchProps,
} from "@headlessui/react"
import clsx from "clsx"
import { t } from "@lingui/macro"

import { Label, Field } from "@src/components/atoms/Fieldset"

export type SwitchProps = {
  checked: boolean
  label?: string
  enabledLabel?: string
  disabledLabel?: string
  className?: string
} & HeadlessSwitchProps

export const Switch = ({
  checked,
  label,
  enabledLabel,
  disabledLabel,
  ...props
}: SwitchProps) => {
  return (
    <Field className="flex items-center gap-2">
      <HeadlessSwitch
        checked={checked}
        className={clsx(
          "relative inline-flex h-7 w-14 items-center rounded border-2 border-blue transition-colors",
          checked ? "bg-blue" : "bg-white",
        )}
        {...props}
      >
        <span className="absolute inset-0 flex items-center justify-between px-1 text-xs text-white">
          <span
            className={clsx(
              "text-white",
              checked ? "opacity-100" : "opacity-0",
            )}
          >
            {enabledLabel || t`Yes`}
          </span>
          <span
            className={clsx("text-blue", checked ? "opacity-0" : "opacity-100")}
          >
            {disabledLabel || t`No`}
          </span>
        </span>
        <span
          className={clsx(
            "inline-block size-5 transform rounded-sm transition-transform",
            checked ? "translate-x-7 bg-white" : "translate-x-1 bg-blue",
          )}
        />
      </HeadlessSwitch>
      <Label className="text-paragraph-medium">{label}</Label>
    </Field>
  )
}
