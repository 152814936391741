import { format } from "date-fns"
import { mapLanguageToDateFnsLocale } from "@src/utils/language"

export type CalendarAnswerProps = {
  answer?: string | null
}

export const CalendarAnswer = ({ answer }: CalendarAnswerProps) => {
  if (!answer) {
    return <span>-</span>
  }

  const formattedDate = format(new Date(answer), "P", {
    locale: mapLanguageToDateFnsLocale(navigator.language),
  })

  return <p>{formattedDate}</p>
}
