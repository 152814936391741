import { useEffect, useMemo } from "react";
import { t, Trans } from "@lingui/macro"
import { z } from "zod"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import {
  getRequestsDefaultCirclesQuery,
  updateRequestsDefaultCircles,
  coachingRequestsKeys
} from "@src/api/requests.ts"
import { getCommunityCirclesQuery } from "@src/api/circle.ts"
import { useForm, Validator } from "@tanstack/react-form"

import { Button } from "@src/components/atoms/Button"
import { Switch } from "@src/components/atoms/Switch"
import { MultiSelect } from "@src/components/atoms/MultiSelect"

type ZodValidator = Validator<
  unknown,
  z.ZodType<unknown, z.ZodTypeDef, unknown>
>

export type SettingsDialogFormValues = {
  defaultCircles: {
    id: number
    label: string
  }[]
  hasDefaultCircles: boolean
}

type SettingsDialogContentProps = {
  communityId: number
  onClose: () => void
}

export const SettingsDialogContent = ({
  communityId,
  onClose
}: SettingsDialogContentProps) => {
  const queryClient = useQueryClient()

  const { data: defaultSettings } = useQuery(
    getRequestsDefaultCirclesQuery(communityId)
  )

  const { data: communityCircles } = useQuery(
    getCommunityCirclesQuery(communityId)
  )

  const { mutate: updatedDefaultSettings,  isPending: isPendingSettingsUpdate } =
    useMutation({
      mutationFn: (circles: number[]) => {
        return updateRequestsDefaultCircles(communityId, circles)
      },
      onSuccess: () => {
        onClose()

        queryClient.invalidateQueries({
          queryKey: coachingRequestsKeys.defaultCircles(communityId)
        })
      },
  })

  const communityCirclesOptions = (communityCircles || []).map(({ id, name }) => ({
    id: id || 0,
    label: name || "",
  }))

  const communityDefaultCircles = (defaultSettings?.defaultCircles || []).map(({ id, name }) => ({
    id: id || 0,
    label: name || "",
  }))

  const form = useForm<SettingsDialogFormValues,ZodValidator>({
    defaultValues: {
      hasDefaultCircles: defaultSettings?.hasDefaultCircles ?? false,
      defaultCircles: communityDefaultCircles
    },
    validatorAdapter: zodValidator(),
    onSubmit: async (values) => {
      const circleIds = values.value.defaultCircles.map(circle => circle.id )
      updatedDefaultSettings(circleIds)

    }
  })

  const hasDefaultCirclesValue = form.useStore(
    (state) => state.values.hasDefaultCircles,
  )

  const defaultCirclesSchema = useMemo(() => {
    const base = z.array(z.object({ id: z.number(), label: z.string() }))
    return hasDefaultCirclesValue
      ? base.min(1, t`You must select at least 1 circle.`)
      : base
  }, [hasDefaultCirclesValue])

  const validator = useMemo(() => {
    return {
      defaultCircles: {
        onChange: defaultCirclesSchema,
        onSubmit: defaultCirclesSchema,
      },
    }
  }, [defaultCirclesSchema])

  useEffect(() => {
    if(!hasDefaultCirclesValue) {
      form.setFieldValue(
        "defaultCircles",
        []
      )
    }
  }, [hasDefaultCirclesValue, form])

  useEffect(() => {
    form.validateField("defaultCircles", "change")
  }, [hasDefaultCirclesValue, form])

  return (
    <>
    <div className="flex flex-col gap-4">
      <div>
        <form.Field
          name="hasDefaultCircles"
          children={(field) => (
            <Switch
              checked={!field.state.value}
              label={t`The startup can decide the privacy of the request`}
              onChange={(checked) => {
                field.handleChange(!checked)
              }}
            />
          )}
        />
      </div>
      <div>
          <form.Field
            name="defaultCircles"
            validators={validator.defaultCircles}
            children={(field) => (
              <MultiSelect
                label={t`Default roles`}
                value={field.state.value}
                options={communityCirclesOptions}
                error={field.state.meta.errors.join(", ")}
                onSelect={(values) => {
                  field.handleChange(values)
                }}
                disabled={!hasDefaultCirclesValue}
              />
            )}
          />
          <span className="text-paragraph-medium font-normal text-pearl-light">
            <Trans>All requests will be shared with this role</Trans>
        </span>
      </div>
      <div className="flex justify-end -m-4 bg-pearl-lighter/15 py-2 px-4 gap-2">
        <Button
          variant="secondary"
          small
          onClick={onClose}
        >
          <Trans>Cancel</Trans>
        </Button>
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button
              type="submit"
              small
              disabled={!canSubmit || isSubmitting || isPendingSettingsUpdate}
              onClick={form.handleSubmit}
            >
              <Trans>Continue</Trans>
            </Button>
          )}
        />

      </div>
    </div>
    </>
  )
}
